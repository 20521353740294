<template>
  <div>
    <div class="line"></div>
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
      text-color="#000"
      active-text-color="#0ec64b"
      :router="true"
    >
      
      
      <!-- <el-submenu index="1">
        <template slot="title">商家列表</template> -->
      <el-menu-item index="/company" ><p class="items">商家详情</p></el-menu-item>
      <el-menu-item index="/MyClient" v-if="roleId == 3 || roleId == 0"
        ><p class="item">我的客户</p></el-menu-item
      >
      <el-menu-item
        index="/CompanyJoin"
        v-if="roleId == 3 || roleId == 1 || roleId == 'null'"
        ><p class="item items">加入商家</p></el-menu-item
      >
      <el-menu-item
        :index="`/CompanyJoinItem?id=${companyId}`"
        v-if="roleId == 5 || roleId == 2"
        ><p class="item items">旗下经纪人</p></el-menu-item
      >
     
      <el-menu-item index="/orderList"
        ><a target="_blank"><p class="item items">订单管理</p></a></el-menu-item
      >
      <el-menu-item
        :index="`/partner?type=${JSON.stringify(1)}`"
        v-if="roleId == 1 || roleId == 'null' || roleId == undefined"
        ><a target="_blank"><p class="item items">公司注册</p></a></el-menu-item
      >
      <el-menu-item
        :index="`/partner?type=${JSON.stringify('kehu')}`"
        v-if="roleId == 1 || roleId == 'null' || roleId == undefined"
        ><a target="_blank"><p class="item items">客户注册</p></a></el-menu-item
      >
      <!-- <el-submenu index='1'  class="searchWorker" v-if="roleId == 1 || roleId == undefined || roleId == 'null'">
        <template slot="title" v-if="roleId == undefined || roleId == 1 || roleId == 'null'">
          <p class="item items">成为合伙人</p>
        </template>
        <el-menu-item  :index="`/partner?type=${JSON.stringify(3)}`"><a target="_blank"><p class="items">城市合伙人</p></a></el-menu-item>
        <el-menu-item  :index="`/partner?type=${JSON.stringify(4)}`"><a target="_blank"><p class=" items">项目合伙人</p></a></el-menu-item>
        <el-menu-item  :index="`/partner?type=${JSON.stringify(5)}`"><a target="_blank"><p class=" items">服务合伙人</p></a></el-menu-item>
      </el-submenu> -->
      <!-- <el-menu-item
        :index="`/partner?type=${JSON.stringify(3)}`"
        v-if="roleId == 1 || roleId == 'null' || roleId == undefined"
        ><a target="_blank"><p class="item items">城市合伙人</p></a></el-menu-item
      >
      <el-menu-item
        :index="`/partner?type=${JSON.stringify(4)}`"
        v-if="roleId == 1 || roleId == 'null' || roleId == undefined"
        ><a target="_blank"><p class="item items">项目合伙人</p></a></el-menu-item
      >
      <el-menu-item
        :index="`/partner?type=${JSON.stringify(5)}`"
        v-if="roleId == 1 || roleId == 'null' || roleId == undefined"
        ><a target="_blank"><p class="item items">服务合伙人</p></a></el-menu-item
      > -->
      <!-- <el-menu-item
        :index="`/partner?type=${JSON.stringify(6)}`"
        v-if="roleId == 1 || roleId == 'null' || roleId == undefined"
        ><a target="_blank">设计师</a></el-menu-item
      > -->
      <el-menu-item
        v-if="roleId == 2 || roleId == 5"
        class="certificate"
        @click="tocertificate()"
        ><p class="item items">我的公司</p></el-menu-item
      >
      <!-- <el-menu-item index="/setInfo" class="certificate" v-if="roleId == 1"
        >情报提供</el-menu-item
      > -->
      <el-menu-item index="/setInfoissue" class="certificate"
        ><p class="item items">供求信息发布</p></el-menu-item
      >
      <el-menu-item index="/goodListInfo"  class="goodListInfo"  
      v-if="roleId == 3"
        ><p class="item items">购物清单</p></el-menu-item>

      <!-- <el-menu-item index="/information"  class="information">
        <p class="item items">价格行情</p></el-menu-item> -->
      
      <!-- <el-menu-item index="/resume"  class="resume"  v-if="roleId == 2" 
        ><p class="item items">厨师直招</p></el-menu-item> -->
      <el-menu-item index="/addGroup"  class="addGroup"  v-if="roleId == 1 || roleId == 11" >
      <p class="item items">班组管理</p></el-menu-item>
      <el-submenu index='2'  class="searchWorker" v-if="roleId == 2 || roleId == undefined">
        <template slot="title" v-if="roleId == undefined || roleId == 2 "><p class="item items">招工求职</p></template>
        <el-menu-item  index="searchWorker"><p class="items">找工作</p></el-menu-item>
        <el-menu-item  index="searchWorkers"><p class="items">找工人</p></el-menu-item>
        <el-menu-item  index="searchTotal"><p class="items">招工求职</p></el-menu-item>
      </el-submenu>


      <!-- <el-menu-item  class="searchWorker"  v-if="roleId == 2 || roleId == undefined"
        ><p>招工求职</p>
        <span class="searchWorker-worker"  v-if="roleId == undefined || roleId == 2 ">
          <div @click="searchWorker">找工作</div>
          <div @click="searchWorkers">找工人</div>
          <div @click="searchWorkers">招工求职</div>
        </span>
        </el-menu-item> -->
       
    </el-menu>

    
    
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeIndex: "1",
      isWorker:false
    };
  },
  // 权限信息
  computed: {
    roleId() {
      return sessionStorage.getItem("roleId");
      
    },
    companyId() {
      return sessionStorage.getItem("companyId");
    },
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    tocertificate(){
      
      this.$router.push("usercertificate");
    }
    // searchWorker(){
    //   this.$router.push('searchWorker')
    // },
    // searchWorkers(){
    //   this.$router.push('searchWorkers')
    // },
    // searchTotal(){
    //   this.$router.push('searchTotal')
    // }
  },
};
</script>
<style scoped>
.items:hover{color:#00c65d}
.item:after{content: "";
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -7px;
    width: 2px;
    height: 14px;
    background: #222;}
/* .el-menu.el-menu--horizontal {
  border: 0;
  
}

.searchWorker{
  position: relative;
} */
/* .searchWorker-worker{
  display: flex;
  position:absolute;
  width: 100px;
  background:rgba(255, 255, 255, 0.9);
  color: #000;
  z-index: 2;
  top: 0px;
  left: 82px;
  display: none;
  text-align: center
} */
/* .searchWorker-worker div:hover{
  color:#00c65d ;
}
.searchWorker:hover .searchWorker-worker{
  display: block;
}
.item2:hover{
color: #00c65d;
} */
</style>